import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { graphql } from 'gatsby'
import { format, parse } from 'date-fns'
import { sv } from 'date-fns/locale'
import Content, { HTMLContent } from '../components/content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  titleArea: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 2),
  },
  image: {
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    // Somehting about the combination of gatsby-image, borderRadius and
    // boxShadow causes a distracting 1 px high white border along the bottom
    // of images, only on Safari.  Using a dark-gray background color makes it
    // less striking.
    backgroundColor: '#444',
    boxShadow: theme.shadows[4],
  },
  content: {
    padding: theme.spacing(4),
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const InfoItem = ({ label, value }) => (
  <Typography variant="body1" paragraph>
    <strong>{label}:</strong> {value}
  </Typography>
)

const formatDateString = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd HH:mm', new Date())
  const dateTime = `${sweFormat(date, 'EEEE d MMMM')} kl ${sweFormat(
    date,
    'H:mm'
  )}`
  const uppercaseDateTime = `${dateTime
    .charAt(0)
    .toUpperCase()}${dateTime.slice(1)}`
  return uppercaseDateTime
}

const sweFormat = (date, formatString) =>
  format(date, formatString, { locale: sv })

export const EventPageTemplate = ({
  title,
  date,
  html,
  location,
  price,
  image,
  contentComponent,
}) => {
  const classes = useStyles()
  const dateTime = formatDateString(date)
  const BodyComponent = contentComponent || Content

  return (
    <>
      <main>
        <Container maxWidth="md">
          <PreviewCompatibleImage
            imageInfo={{ image }}
            className={classes.image}
          />
        </Container>
        <div className={classes.titleArea}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {title}
            </Typography>
          </Container>
        </div>
        <div className={classes.content}>
          <Container maxWidth="sm">
            <InfoItem label="Tid" value={dateTime} />
            <InfoItem label="Plats" value={location} />
            <InfoItem label="Inträde" value={price} />
            <Typography variant="body1" component="div">
              <BodyComponent content={html} />
            </Typography>
          </Container>
        </div>
      </main>
    </>
  )
}

EventPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  contentComponent: PropTypes.func,
}

const EventPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <EventPageTemplate
      html={html}
      contentComponent={HTMLContent}
      {...frontmatter}
    />
  )
}

EventPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default EventPage

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        location
        price
        title
        image {
          childImageSharp {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
